<script setup lang="ts">
import DesktopNav from "./DesktopNav.vue";
import MobileNav from "./MobileNav.vue";
import AppLogo from "@/layouts/partials/marketing/AppLogo.vue";
import { useRootStore } from "@/store/root";

const rootStore = useRootStore();
const { gtag } = useGtag();

const navigation = [
  { name: "Blog", href: ROUTES.blog },
  { name: "Contact", href: ROUTES.contact },
];
const dropDownMenus = [
  {
    name: "For employers",
    basePath: ROUTES.employers,
    items: [
      {
        name: "Getting started",
        href: ROUTES.employers,
        icon: "material-symbols:rocket-launch",
      },
      {
        name: "Features",
        href: ROUTES.employersFeatures,
        icon: "material-symbols:settings-suggest",
      },
      { name: "Pricing", href: ROUTES.employersPricing, icon: "tabler:coins" },
      { name: "Sign up", href: ROUTES.register, icon: "material-symbols:person-add" },
    ],
    // cta: {
    //   name: "Browse candidates",
    //   href: ROUTES.employersBrowseCandidates,
    //   desc: "Preview some job seekers.",
    //   icon: "material-symbols:person-search",
    // },
  },
  {
    name: "Find a job",
    basePath: ROUTES.candidates,
    items: [
      { name: "Getting started", href: ROUTES.candidates, icon: "material-symbols:rocket-launch" },
      {
        name: "Features",
        href: ROUTES.candidatesFeatures,
        icon: "material-symbols:settings-suggest",
      },
      {
        name: "Download the app",
        action: () => toggleDownloadAppModal(true),
        icon: "material-symbols:app-promo-rounded",
      },
    ],
  },
];

const toggleDownloadAppModal = (active: boolean) => {
  rootStore.toggleDownloadAppModal(active);
};

const sendEmployerRegisterEvent = () => {
  gtag("event", ANALYTICS_EVENTS.employerSignupLinkClicked);
};

onMounted(() => {
  const header = document.querySelector(".header");
  if (!header) return;
  window.addEventListener("scroll", () => {
    const scrollY = window.scrollY;
    if (scrollY > 0) {
      header.classList.add("header-sticky");
    } else {
      header.classList.remove("header-sticky");
    }
  });
});
</script>

<template>
  <header class="sticky header inset-x-0 top-0 z-40 transition-all duration-300 text-dark-gray">
    <nav class="mx-auto flex items-center justify-between max-w-7xl px-6 py-4 lg:px-8 lg:gap-x-5 xl:gap-x-8" aria-label="Global">
      <div class="flex lg:flex-shrink-0">
        <NuxtLink :to="ROUTES.home" class="-m-1.5 p-1.5">
          <span class="sr-only">{{ $config.public.appEmail }}</span>
          <AppLogo />
        </NuxtLink>
      </div>

      <DesktopNav
        :drop-down-menus="dropDownMenus"
        :navigation="navigation"
        @candidate-auth-selected="toggleDownloadAppModal(true)"
        @employer-register-selected="sendEmployerRegisterEvent()"
      />
      <MobileNav
        :drop-down-menus="dropDownMenus"
        :navigation="navigation"
        @candidate-auth-selected="toggleDownloadAppModal(true)"
        @employer-register-selected="sendEmployerRegisterEvent()"
      />
    </nav>
  </header>
</template>
